<template>
  <div :class="{ 'csn-blur-cover': hasMenu }"></div>
</template>
<script>
import { BLUR_COVER } from '@/constants'

export default {
  name: BLUR_COVER,
  props: {
    hasMenu: [Boolean, Object],
  },
}
</script>
